import React, {useState, useRef, useEffect} from "react";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {useActivate, useUnactivate} from "react-activation";
import {NavBar, Form, Input, TextArea, Checkbox, Button, CascadePicker, Popup, Stepper, Space} from "antd-mobile";
import moment from "moment";
import ChuangShiOS from "chuangshi-os-mobile-component";

import Application from "../../common/Application";

import Calendar from "../../component/calendar/Index";
import Coupon from "./Coupon";

import Style from "./Confirm.module.css";

const TradeConfirm = (props) => {
    const store = useSelector((store) => store);
    const [form] = Form.useForm();
    const request = useRef(false);
    const [isLaunch, setIsLaunch] = useState(false);
    const [isQuantity, setIsQuantity] = useState(false);
    const [maxQuantity, setMaxQuantity] = useState(0);
    const [isReserveDate, setIsReserveDate] = useState(false);
    const [reserveDate, setReserveDate] = useState("");
    const [isReserveTime, setIsReserveTime] = useState(false);
    const [reserveTimeList, setIsReserveTimeList] = useState([]);
    const [minDate, setMinDate] = useState(0);
    const [maxDate, setMaxDate] = useState(0);
    const [calendarList, setCalendarList] = useState([]);
    const [isAgree, setIsAgree] = useState(false);
    const [isAgreement, setIsAgreement] = useState(false);
    const [agreement, setAgreement] = useState("");
    const [activityId,setActivityId] = useState("");
    const [activityType, setActivityType] = useState(false);
    const [activityIsCoupon, setActivityIsCoupon] = useState(false);
    const [activityCouponCnt, setActivityCouponCnt] = useState(0);// 可用券数量
    const [activityIsPayment, setActivityIsPayment] = useState(false);
    const [activityIsPoints, setActivityIsPoints] = useState(false);
    const [isSubmit, setIsSubmit] = useState(true);
    const [isCouponCode,setIsCouponCode] = useState(false); //选择优惠券
    const [disAmt,setDisAmt] = useState(0);// 优惠金额
    const [txnAmt,setTxnAmt] = useState(0);// 优惠后订单金额
    const [oriTxnAmt,setOriTxnAmt] = useState(0);// 订单金额
    const [disPoints,setDisPoints] = useState(0);// 优惠积点
    const [txnPoints,setTxnPoints] = useState(0);// 优惠后订单积点
    const [oriTxnPoints,setOriTxnPoints] = useState(0);// 订单积点

    useEffect(() => {
        setActivityId(props.match.params.activityId);
        handleLoadAgreement();
        let reserveDate = moment(Number(props.match.params.reserveDate)).format("YYYY-MM-DD");
        form.setFieldsValue({
            reserveDate: reserveDate,
        });
        setReserveDate(reserveDate);
        handleLoadDate();
        handleLoadTime(reserveDate);

        setTxnAmt(getGoodsStorage().goodsSkuSalePrice||0)
        setOriTxnAmt(getGoodsStorage().goodsSkuSalePrice||0)
        setTxnPoints(getGoodsStorage().goodsSkuPoints||0)
        setOriTxnPoints(getGoodsStorage().goodsSkuPoints||0)
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        let data = {
            reserveRemark: "",
        };
        data.reserveName = store.main.member.memberName || localStorage.getItem("reservationName");
        data.reservePhone = store.main.member.memberPhone || localStorage.getItem("reservationPhone");
        form.setFieldsValue(data);
        //eslint-disable-next-line
    }, [store.main.member.memberPhone]);

    useActivate(() => {});

    useUnactivate(() => {});

    // 读取商品信息页面保存的信息
    const getGoodsStorage = ()=>{
        return JSON.parse(localStorage.getItem("windeal-h5-goods")||"{}")
    }

    const handleLoadAgreement = () => {
        if (request.current) {
            return;
        }
        request.current = true;

        let url = "";
        let data = {};
        switch (props.match.path) {
            case "/trade/activity/confirm/:activityId/:activityGoodsSkuId/:reserveDate":
                url = "/windeal/activity/consumer/mobile/v1/agreement/find";

                data = {
                    activityGoodsSkuId: props.match.params.activityGoodsSkuId,
                };

                data.custNo = localStorage.getItem("custNo");
                data.actId = localStorage.getItem("actId");
                data.svcProjectId = localStorage.getItem("svcProjectId");
                data.source = localStorage.getItem("source");
                data.operateUser = localStorage.getItem("operateUser");
                data.activityMaxQuantity = localStorage.getItem("activityMaxQuantity");

                break;
            case "/trade/benefit/confirm/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId/:reserveDate":
                url = "/windeal/member/benefit/consumer/mobile/v1/agreement/find";

                data = {
                    memberBenefitId: props.match.params.memberBenefitId,
                    benefitCategoryId: props.match.params.benefitCategoryId,
                    benefitGoodsSkuId: props.match.params.benefitGoodsSkuId,
                };

                break;
            default:
                break;
        }

        ChuangShiOS.Util.request(props, {
            url: Application.host + url,
            data: data,
            success: (response) => {
                switch (props.match.path) {
                    case "/trade/activity/confirm/:activityId/:activityGoodsSkuId/:reserveDate":
                        setAgreement(response.data.activityAgreement);
                        setActivityIsPayment(response.data.activityIsPayment);
                        setActivityIsCoupon(response.data.activityIsCoupon);
                        setActivityCouponCnt(response.data.activityCouponCnt);
                        setActivityIsPoints(response.data.activityIsPoints);
                        setActivityType(response.data.activityType);
                        setIsQuantity(response.data.activityIsQuantity);
                        setMaxQuantity(response.data.activityMaxQuantity);
                        if (response.data.activityIsQuantity && response.data.activityIsQuantity  === true) {
                            let ok = Number.isFinite(response.data.activityMaxQuantity)
                                && response.data.activityMaxQuantity > 0;
                            setIsSubmit(ok);
                        }
                        break;
                    case "/trade/benefit/confirm/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId/:reserveDate":
                        setAgreement(response.data.benefitAgreement);

                        break;
                    default:
                        break;
                }
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;

                setIsLaunch(true);
            },
        });
    };

    const handleLoadDate = () => {
        let url = "";
        let data = {};
        switch (props.match.path) {
            case "/trade/activity/confirm/:activityId/:activityGoodsSkuId/:reserveDate":
                url = "/windeal/activity/consumer/mobile/v1/date/find";

                data = {
                    activityGoodsSkuId: props.match.params.activityGoodsSkuId,
                };

                break;
            case "/trade/benefit/confirm/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId/:reserveDate":
                url = "/windeal/member/benefit/consumer/mobile/v1/date/find";

                data = {
                    memberBenefitId: props.match.params.memberBenefitId,
                    benefitCategoryId: props.match.params.benefitCategoryId,
                    benefitGoodsSkuId: props.match.params.benefitGoodsSkuId,
                };

                break;
            default:
                break;
        }

        ChuangShiOS.Util.request(props, {
            url: Application.host + url,
            data: data,
            success: (response) => {
                setMinDate(response.data.minDate);
                setMaxDate(response.data.maxDate);
                setCalendarList(response.data.calendarList);

                switch (props.match.path) {
                    case "/trade/activity/confirm/:activityId/:activityGoodsSkuId/:reserveDate":
                        break;
                    case "/trade/benefit/confirm/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId/:reserveDate":
                        setIsSubmit(response.data.benefitIsSave);

                        break;
                    default:
                        break;
                }
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {},
        });
    };

    const handleLoadTime = (reserveDate) => {
        let url = "";
        let data = {};
        switch (props.match.path) {
            case "/trade/activity/confirm/:activityId/:activityGoodsSkuId/:reserveDate":
                url = "/windeal/activity/consumer/mobile/v1/time/find";

                data = {
                    activityGoodsSkuId: props.match.params.activityGoodsSkuId,
                    reserveDate: reserveDate,
                };

                break;
            case "/trade/benefit/confirm/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId/:reserveDate":
                url = "/windeal/member/benefit/consumer/mobile/v1/time/find";

                data = {
                    benefitGoodsSkuId: props.match.params.benefitGoodsSkuId,
                    reserveDate: reserveDate,
                };

                break;
            default:
                break;
        }

        ChuangShiOS.Util.request(props, {
            url: Application.host + url,
            data: data,
            success: (response) => {
                let excludeTimeList = [];
                if (response.data.exclude) {
                    for (let i = 0; i < 24; i++) {
                        if (i >= response.data.excludeStartHour && i <= response.data.excludeEndHour) {
                            for (let j = 0; j < 60; j++) {
                                if (i == response.data.excludeStartHour) {
                                    if (j >= response.data.excludeStartMinute) {
                                        let value = (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j);
                                        excludeTimeList.push(value);
                                    }
                                } else if (i == response.data.excludeEndHour) {
                                    if (j <= response.data.excludeEndMinute) {
                                        let value = (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j);
                                        excludeTimeList.push(value);
                                    }
                                } else {
                                    let value = (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j);
                                    excludeTimeList.push(value);
                                }
                            }
                        }
                    }
                }

                let reserveTimeList = [];
                for (let i = 0; i < 24; i++) {
                    if (i >= response.data.startHour && i <= response.data.endHour) {
                        let children = [];
                        for (let j = 0; j < 60; j++) {
                            if (i == response.data.startHour) {
                                if (j >= response.data.startMinute) {
                                    let value = (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j);
                                    if (excludeTimeList.indexOf(value) == -1) {
                                        children.push({
                                            label: j < 10 ? "0" + j : j,
                                            value: value,
                                        });
                                    }
                                }
                            } else if (i == response.data.endHour) {
                                if (j <= response.data.endMinute) {
                                    let value = (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j);
                                    if (excludeTimeList.indexOf(value) == -1) {
                                        children.push({
                                            label: j < 10 ? "0" + j : j,
                                            value: value,
                                        });
                                    }
                                }
                            } else {
                                let value = (i < 10 ? "0" + i : i) + ":" + (j < 10 ? "0" + j : j);
                                if (excludeTimeList.indexOf(value) == -1) {
                                    children.push({
                                        label: j < 10 ? "0" + j : j,
                                        value: value,
                                    });
                                }
                            }
                        }

                        if (children.length > 0) {
                            reserveTimeList.push({
                                label: i < 10 ? "0" + i : i,
                                value: i < 10 ? "0" + i : i,
                                children: children,
                            });
                        }
                    }
                }
                setIsReserveTimeList(reserveTimeList);
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {},
        });
    };

    const handleSubmit = (data) => {
        if (data.quantity <= 0) {
            ChuangShiOS.Util.toastError({
                message: "请输入兑换次数",
                complete: () => {},
            });
            return;
        }
        if (activityIsPoints && txnPoints!==0){
            ChuangShiOS.Util.toastError({
                message: "请用优惠券抵扣完剩余点数再提交订单",
                complete: () => {},
            });
            return;
        }

        if (request.current) {
            return;
        }
        request.current = true;

        ChuangShiOS.Util.toastLoad();

        let url = "";
        switch (props.match.path) {
            case "/trade/activity/confirm/:activityId/:activityGoodsSkuId/:reserveDate":
                url = "/windeal/trade/consumer/mobile/v1/activity/save";
                if (activityType == "HSBC") {
                    url = "/windeal/trade/consumer/mobile/v1/hsbc/save";
                } else if (activityType == "CEB") {
                    url = "/windeal/trade/consumer/mobile/v1/ceb/save";
                } else if (activityType == "EFUND") {
                    url = "/windeal/trade/consumer/mobile/v1/efund/save";
                    data.custNo = localStorage.getItem("custNo");
                    data.actId = localStorage.getItem("actId");
                    data.svcProjectId = localStorage.getItem("svcProjectId");
                    data.source = localStorage.getItem("source");
                    data.operateUser = localStorage.getItem("operateUser");
                    data.activityMaxQuantity = localStorage.getItem("activityMaxQuantity");
                } else if (activityType == "BLSX") {
                    url = "/windeal/trade/consumer/mobile/v1/blsx/save";
                    data.custId = localStorage.getItem("custId");
                    data.cid = localStorage.getItem("cid");
                    data.projectId = localStorage.getItem("projectId");
                }
                data.activityId = props.match.params.activityId;
                data.activityGoodsSkuId = props.match.params.activityGoodsSkuId;

                break;
            case "/trade/benefit/confirm/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId/:reserveDate":
                url = "/windeal/trade/consumer/mobile/v1/benefit/save";
                data.memberBenefitId = props.match.params.memberBenefitId;
                data.benefitGoodsSkuId = props.match.params.benefitGoodsSkuId;
                break;
            default:
                break;
        }

        data.quantity = isQuantity ? data.quantity : 1;
        data.reserveQuantity = isQuantity ? 1 : data.reserveQuantity;
        data.reserveTime = data.reserveDate + " " + data.reserveTime;

        delete data.reserveDate;

        //缓存预订姓名与手机号
        localStorage.setItem("reservationName", data.reserveName);
        localStorage.setItem("reservationPhone", data.reservePhone);

        ChuangShiOS.Util.request(props, {
            url: Application.host + url,
            data: data,
            success: (response) => {
                ChuangShiOS.Util.toastHide();
                switch (props.match.path) {
                    case "/trade/activity/confirm/:activityId/:activityGoodsSkuId/:reserveDate":
                        if (activityType == "HSBC") {
                            window.location.replace(response.data.paymentUrl);
                        } else if (activityType == "CEB") {
                            ChuangShiOS.Util.redirectTo(props, {
                                url: "/trade/activity/result/" + props.match.params.activityId + "/" + response.data.tradeId,
                            });
                        } else if (activityType == "EFUND") {
                            ChuangShiOS.Util.redirectTo(props, {
                                url: "/trade/activity/result/efund/" + props.match.params.activityId + "/" + response.data.tradeId,
                            });
                        } else if (activityType == "BLSX") {
                            ChuangShiOS.Util.redirectTo(props, {
                                url: "/trade/activity/result/" + props.match.params.activityId + "/" + response.data.tradeId,
                            });
                        } else {
                            ChuangShiOS.Util.redirectTo(props, {
                                url: "/trade/activity/result/" + props.match.params.activityId + "/" + response.data.tradeId,
                            });
                        }
                        break;
                    case "/trade/benefit/confirm/:memberBenefitId/:benefitCategoryId/:benefitGoodsSkuId/:reserveDate":
                        ChuangShiOS.Util.redirectTo(props, {
                            url: "/trade/benefit/result/" + response.data.tradeId,
                        });
                        break;
                    default:
                        break;
                }
            },
            error: (response) => {
                ChuangShiOS.Util.toastError({
                    message: response.message,
                    complete: () => {},
                });
            },
            complete: (response) => {
                request.current = false;
            },
        });
    };

    // 选择优惠券后的回调,参数为券码、优惠后金额、优惠后的订单金额
    const couponDiscounts = (codes,disAmt,txnAmt)=>{
        console.log(codes," disAmt:",disAmt," txnAmt:",txnAmt)
        form.setFieldsValue({couponCode: codes.join(","),couponName:"已选"+codes.length+"张优惠券"});
        setDisAmt(disAmt);
        setTxnAmt(txnAmt);
        setIsCouponCode(false);
    }
    // 积点兑换
    const couponDiscountsPoints = (codes,disPoints,txnPoints)=>{
        console.log(codes," disPoints:",disPoints," txnPoints:",txnPoints)
        form.setFieldsValue({couponCode: codes.join(","),couponName:"已选"+codes.length+"张优惠券"});
        setDisPoints(disPoints);
        setTxnPoints(txnPoints);
        setIsCouponCode(false);
    }

    return (
        <ChuangShiOS.Page>
            <NavBar
                back="返回"
                style={{
                    "--border-bottom": "1px #eee solid",
                }}
                onBack={() => {
                    ChuangShiOS.Util.navigateBack(props);
                }}
            >
                预约信息
            </NavBar>
            <ChuangShiOS.Content isLaunch={isLaunch} isEmpty={false}>
                <div className={Style.empty}></div>
                <Form
                    form={form}
                    layout="horizontal"
                    footer={
                        <Button block disabled={!isAgree || !isSubmit} type="submit" color="primary">
                            {isSubmit ? "提交订单" : "权益已用完"}
                        </Button>
                    }
                    onFinish={handleSubmit}
                >
                    {isQuantity ? (
                        <Form.Item
                            name="quantity"
                            initialValue={maxQuantity > 0 ? 1 : 0}
                            label="兑换次数"
                            childElementPosition="right"
                            rules={[{required: true, message: "请输入兑换次数"}]}
                        >
                            <Stepper min={1} max={maxQuantity} />
                        </Form.Item>
                    ) : null}
                    <Form.Item name="reserveName" label="预定姓名" rules={[{required: true, message: "请输入预定姓名"}]}>
                        <Input type="text" placeholder="请输入预定姓名" />
                    </Form.Item>
                    <Form.Item
                        name="reservePhone"
                        label="预定手机"
                        rules={[
                            {required: true, message: "请输入预定手机"},
                            {
                                validator: (event, value) => {
                                    if (ChuangShiOS.Util.isPhone(value)) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject(new Error("手机格式不对"));
                                    }
                                },
                            },
                        ]}
                    >
                        <Input placeholder="请输入预定手机" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" />
                    </Form.Item>
                    {isQuantity ? null : (
                        <Form.Item
                            name="reserveQuantity"
                            initialValue={1}
                            label="出席人数"
                            childElementPosition="right"
                            rules={[{required: true, message: "请输入出席人数"}]}
                        >
                            <Stepper min={1} />
                        </Form.Item>
                    )}

                    <Form.Item
                        name="reserveDate"
                        label="预定日期"
                        trigger="onConfirm"
                        rules={[{required: true, message: "请选择预定日期"}]}
                        onClick={() => {
                            setIsReserveDate(true);
                        }}
                    >
                        <Input readOnly placeholder="请选择预定日期" />
                    </Form.Item>
                    <Form.Item
                        name="reserveTime"
                        label="预定时间"
                        trigger="onConfirm"
                        rules={[{required: true, message: "请选择预定时间"}]}
                        onClick={() => {
                            if (minDate < maxDate) {
                                setIsReserveTime(true);
                            } else {
                                ChuangShiOS.Util.toastWarning({
                                    message: "没有可预定时间",
                                    complete: () => {},
                                });
                            }
                        }}
                    >
                        <Input readOnly placeholder="请选择预定时间" />
                    </Form.Item>
                    <Form.Item name="reserveRemark" label="备注说明" rules={[{required: false, message: "请输入备注说明"}]}>
                        <TextArea type="text" placeholder="请输入备注说明" />
                    </Form.Item>
                    {/*在线支付*/}
                    {activityIsPayment?(
                        <React.Fragment>
                            <Form.Item hidden={true} name="couponCode" label="优惠券码" rules={[{required: false, message: "请选择优惠券"}]} onClick={()=>setIsCouponCode(true)}>
                                <Input readOnly placeholder="券码" />
                            </Form.Item>
                            {activityIsCoupon?(
                                <Form.Item name="couponName" label="使用优惠" rules={[{required: false, message: "请选择优惠券"}]} onClick={()=>setIsCouponCode(true)}>
                                    <Input readOnly placeholder={activityCouponCnt>0?"有 "+activityCouponCnt+" 张可用优惠券":"无可用优惠券"} />
                                </Form.Item>
                            ):null}
                            <Form.Item name="txnAmt" label="订单金额" childElementPosition="right">
                                <Space>
                                    {activityIsCoupon?(<div>已优惠：<span className={Style.disAmt}>¥{parseFloat(disAmt.toFixed(2))}</span></div>):null}
                                    <div>小计：<span className={Style.txnAmt}>¥{parseFloat(txnAmt.toFixed(2))}</span></div>
                                </Space>
                            </Form.Item>
                        </React.Fragment>
                    ):null}
                    {/*积点兑换*/}
                    {activityIsPoints?(
                        <React.Fragment>
                        <Form.Item hidden={true} name="couponCode" label="优惠券码" rules={[{required: false, message: "请选择优惠券"}]} onClick={()=>setIsCouponCode(true)}>
                            <Input readOnly placeholder="券码" />
                        </Form.Item>
                        <Form.Item name="couponName" label="使用优惠" rules={[{required: false, message: "请选择优惠券"}]} onClick={()=>setIsCouponCode(true)}>
                            <Input readOnly placeholder="查看优惠券" />
                        </Form.Item>
                        <Form.Item name="txnAmt" label="订单金额" childElementPosition="right">
                            <Space>
                                <div>优惠券抵扣 <span className={Style.disAmt}>{parseFloat(disPoints.toFixed(2))}积点</span></div>
                                <div>还需 <span className={Style.txnAmt}>{parseFloat(txnPoints.toFixed(2))}积点</span></div>
                            </Space>
                        </Form.Item>
                    </React.Fragment>):null}
                </Form>
                <div className={Style.checkbox}>
                    <Checkbox onChange={(value) => {setIsAgree(value);}}>
                        <span className={Style.checkbox_text}>阅读并且同意</span>
                    </Checkbox>
                    <span
                        className={Style.agreement}
                        onClick={(event) => {
                            setIsAgreement(true);
                            event.preventDefault();
                        }}>
                        《用户使用协议》
                    </span>
                </div>
            </ChuangShiOS.Content>
            <Popup
                visible={isReserveDate}
                onMaskClick={() => {
                    setIsReserveDate(false);
                }}
                afterClose={() => {}}
            >
                <Calendar
                    value={reserveDate}
                    title="预定日期"
                    startDay={minDate}
                    endDay={maxDate}
                    calendarList={calendarList}
                    onClose={() => {
                        setIsReserveDate(false);
                    }}
                    onConfirm={(value) => {
                        setIsReserveDate(false);

                        let data = form.getFieldsValue();
                        if (value && value != data.reserveDate) {
                            setReserveDate(value);

                            form.setFieldsValue({
                                reserveDate: value,
                                reserveTime: "",
                            });

                            handleLoadTime(value);
                        }
                    }}
                />
            </Popup>
            <CascadePicker
                visible={isReserveTime}
                options={reserveTimeList}
                title="预定时间"
                onClose={() => {
                    setIsReserveTime(false);
                }}
                onConfirm={(value) => {
                    setIsReserveTime(false);

                    form.setFieldsValue({
                        reserveTime: value[1],
                    });
                }}
            ></CascadePicker>
            <Popup
                visible={isAgreement}
                direction="bottom"
                onMaskClick={() => {
                    setIsAgreement(false);
                }}
            >
                <div className={Style.agreement_content} dangerouslySetInnerHTML={{__html: agreement}}></div>
            </Popup>
            {
                /*选择优惠券*/
                (<Popup visible={isCouponCode} closeOnMaskClick destroyOnClose showCloseButton onClose={() => setIsCouponCode(false)} bodyStyle={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px', background:"#F4F4F4"}}>
                    <Coupon
                        activityId={activityId}
                        txnAmt={oriTxnAmt}
                        couponDiscounts={(codes,disAmt,txnAmt)=>couponDiscounts(codes,disAmt,txnAmt)}
                        txnPoints={oriTxnPoints}
                        couponDiscountsPoints={(codes,disPoints,txnPoints)=>couponDiscountsPoints(codes,disPoints,txnPoints)}
                        activityIsPoints={activityIsPoints}
                    ></Coupon>
                </Popup>)
            }

        </ChuangShiOS.Page>
    );
};

TradeConfirm.propTypes = {};

TradeConfirm.defaultProps = {};

export default withRouter(TradeConfirm);
